import { useEffect } from "react"
//import {StyleSheet, View} from 'react-native'
import { embedDashboard } from "@superset-ui/embedded-sdk"
import "./App.css"

function App() {
    const getToken = async () => {
    const response = await fetch("https://reacttoken.mylinstitute.com/api/guest-token",{headers: {'Content-Type':'application/json',},mode: 'cors'})
    //const response = await fetch("http://67.205.141.240:3001/api/guest-token",{headers: {'Content-Type':'application/json',},mode: 'cors'})
    //const response = await fetch("http://67.205.141.240:3001/api/guest-token",{mode: 'cors'})
    const token = await response.json()
    return token.token
  }

  useEffect(() => {
    const embed = async () => {
      await embedDashboard({
        id: "b0bb3423-3013-4877-af17-7b2d5bd29d36", // given by the Superset embedding UI
        supersetDomain: "https://analytics.mylinstitute.com",
        mountPoint: document.getElementById("dashboard"), // html element in which iframe render
        fetchGuestToken: () => getToken(),
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          hideTab: true,
        },
      })
    }
    if (document.getElementById("dashboard")) {
      embed()
    }
  }, [])
  //<ul style={{listStyleType: "none", display: "block"}}>
  return (
    <div className="App">
      <h1>All About Guyana Stats and Facts</h1>
      <h2>SOCIO-ECONOMIC FACTS & INVESTMENT INSIGHTS</h2>
      <div className="App-menu">
       <ul className="App-menu-item">
        <li className="App-menu-item"><a className="App-menu" href="https://mylinstitute.com">Home</a></li>
        <li className="App-menu-item"><a className="App-menu"  href="https://mylinstitute.com/contact-2" target="_blank">Contact Us</a></li>
       </ul>      
      </div>      
      <div><p>Enter the <span style={{color: "blue"}}><a className="App-text" href="https://analytics.mylinstitute.com" target="_blank">Analytics Portal</a></span> to see additional stats and advanced analysis. <span style={{color: "Green"}}><a className="App-text" href="https://mylinstitute.com/contact-2" target="_blank">Contact US</a></span> to subscribe to the portal.</p></div>
      <div id="dashboard"/>
    </div>
  )
}

export default App

