// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe { width: 100%; height: 800px; border: 0; overflow: hidden; }

.App-menu {
  text-align: left; 
  position: relative;
  left: 17px;
  font-weight: bold;
  background-color: #245376;
  color: white;
  text-decoration:none;
  /*display: block;*/  
}

.App-menu-item {
  display:flex;
  flex-direction: row; 
  padding-left: 0px;
  padding-right: 10px;
  /*text-decoration: none;*/
  /*flex-direction: row; */
  /*listStyleType: none;*/
  /*justifyContent: space-between;*/
}

.App-text{
  text-decoration:none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA,SAAS,WAAW,EAAE,aAAa,EAAE,SAAS,EAAE,gBAAgB,EAAE;;AAElE;EACE,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,wBAAwB;EACxB,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\niframe { width: 100%; height: 800px; border: 0; overflow: hidden; }\n\n.App-menu {\n  text-align: left; \n  position: relative;\n  left: 17px;\n  font-weight: bold;\n  background-color: #245376;\n  color: white;\n  text-decoration:none;\n  /*display: block;*/  \n}\n\n.App-menu-item {\n  display:flex;\n  flex-direction: row; \n  padding-left: 0px;\n  padding-right: 10px;\n  /*text-decoration: none;*/\n  /*flex-direction: row; */\n  /*listStyleType: none;*/\n  /*justifyContent: space-between;*/\n}\n\n.App-text{\n  text-decoration:none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
